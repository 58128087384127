import type { RetentionSource } from '../types'

const RETENTION_SOURCE_KEY = 'retention-source'
const RETENTION_SKIP_FOR_REG = 'retention-skip-for-reg'
const RETENTION_DELEY_KEY = 'retention-deley'

export function sourceCacheSet(direction: RetentionSource, rewrite: boolean = false) {
  const cache = localStorage.getItem(RETENTION_SOURCE_KEY)
  if (cache && !rewrite) return
  sessionStorage.setItem(RETENTION_SOURCE_KEY, direction)
}

export function sourceCacheGet(): RetentionSource | null {
  return sessionStorage.getItem(RETENTION_SOURCE_KEY) as RetentionSource
}

export function sourceCacheClear() {
  sessionStorage.removeItem(RETENTION_SOURCE_KEY)
}

export function regSkipCacheSet() {
  sessionStorage.setItem(RETENTION_SKIP_FOR_REG, 'true')
}

export function regSkipCacheGet(): boolean {
  return sessionStorage.getItem(RETENTION_SKIP_FOR_REG) === 'true'
}

export function regSkipCacheClear() {
  sessionStorage.removeItem(RETENTION_SKIP_FOR_REG)
}

export function deleyCacheGet(): number {
  const deley = sessionStorage.getItem(RETENTION_DELEY_KEY)
  return deley ? parseInt(deley) : Date.now()
}

export function deleyCacheSet() {
  sessionStorage.setItem(RETENTION_DELEY_KEY, Date.now().toString())
}
